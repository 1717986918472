import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  useHistory,
  useLocation,
} from "react-router-dom";
import "./App.css";
import { StateProvider } from "./hooks/useGlobalState";
import Routes from "./Routes";
import Layout from "./Layout";
import CustomLoader from "./components/CustomLoader/CustomLoader";
import "./services/axiosUtilService";
import { decodeParams } from "./commom/basicAuth";
import {
  getInsertSMDetails,
  getValidateUser,
  getValidatedUser,
} from "./services/loginUserValidate";
import { decryptDataCBC, encryptUsingCBC } from "../src/commom/basicAuth";
import { confirmAlert } from "react-confirm-alert";

// import './scss/main.scss'

function DefaultRoute() {
  const history = useHistory();
  const location = useLocation();
  let [loading, setLoading] = useState(false);

  const validateUrl = async () => {
    if (window.flutter_inappwebview) {
      let encodedQueryString = location.search.substring(1);
      let searchParams = decryptDataCBC(encodedQueryString);
      console.log("searchParams", searchParams);

      if (searchParams.storeNo && searchParams.userId) {
        sessionStorage.setItem("params", encodedQueryString);
        UserLogin(searchParams.userId, searchParams?.storeNo);
      } else {
        const callNativeRes = await window.flutter_inappwebview.callHandler(
          "askparvez",
          document.title
        );
        sessionStorage.setItem("params", callNativeRes.Authorization);

        searchParams = decryptDataCBC(callNativeRes.Authorization);
        UserLogin(searchParams.userId, searchParams?.storeNo);
      }
    } else {
      history.push("/loginNew");
    }
  };

  useEffect(() => {
    validateUrl();
  }, []);

  const UserLogin = async (userId, StoreNo) => {
    // const history = useHistory();

    try {
      let request = {
        userId: userId,
        storeId: StoreNo,
      };
      setLoading(true);

      const response = await getValidatedUser(request);
      console.log("LOG RES", response);
      if (response.errorCode === "00") {
        sessionStorage.setItem("GUID", response.guid);
        setLoading(false);
        history.push("/storeCommunication");
        // sessionStorage.setItem(
        //   "GUID",
        //   "523c3aa8-e59f-453f-bb74-167c9ac34d4b:INT9"
        // );
      } else {
        setLoading(false);
        confirmAlert({
          title: "Alert",
          message: response.errorMsg,
          buttons: [
            {
              label: "OK",
              onClick: () => {
                return false;
              },
            },
          ],
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  if (loading) {
    return <CustomLoader loading={loading} />;
  }
  return null;
}

function App() {
  return (
    <React.Suspense fallback={<CustomLoader />}>
      {/* {window.flutter_inappwebview ? ( */}
      <StateProvider>
        <Router basename={process.env.PUBLIC_URL}>
          <DefaultRoute />
          <Layout>
            <Routes />
          </Layout>
        </Router>
      </StateProvider>
      {/* ) : (
        <h4 style={{ padding: "10px" }}>
          Oh no... Looks like you have reached the wrong address.
        </h4>
      )} */}
    </React.Suspense>
  );
}

export default App;
