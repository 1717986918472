import * as CryptoJS from "crypto-js";
var crypto = require("crypto");
export function basicAuth(userId) {
  var base64 = require("base-64");
  var encoded = base64.encode(userId + ":" + getValues(userId)).toString();
  return encoded;
}

export function decodeParams(params) {
  try {
    let base64 = require("base-64");
    let decoded = base64.decode(params).toString();
    let decodedString = new URLSearchParams(decoded);
    return {
      storeNo: decodedString.get("storeNo"),
      type: decodedString.get("type"),
      userId: decodedString.get("userId"),
    };
  } catch (e) {
    return {};
  }
}

const removePIfStartsWithP = (str) => {
  if (str.toLowerCase().startsWith("p")) {
    return str.slice(1);
  } else {
    return str;
  }
};

const edKey = "7679907FC629178A15C530EC4FF6E683";

export const encryptUsingCBC = (str) => {
  const key = Buffer.from(edKey, "utf-8");
  const iv = Buffer.from(edKey.substring(0, 16), "utf-8");

  const cipher = crypto.createCipheriv("aes-256-cbc", key, iv);

  let encrypted = cipher.update(str, "utf-8", "base64");
  encrypted += cipher.final("base64");

  return encrypted;
};

export const decryptDataCBC = (encryptedStr) => {
  // const encryptedStr= "IkvPnyVs37rg+NDKDsACf2ZO11ilLqn32UbqF8AZAUd/xcVoxoSmS7o6C2uyuU8QUglmaRr014VNcOR0DrQ2h90HE4tRr/wQ/+31kQ1hFYA="
  try {
    const key = CryptoJS.enc.Utf8.parse(edKey);
    const iv = CryptoJS.lib.WordArray.create(new Array(16).fill(0));

    const decrypted = CryptoJS.AES.decrypt(encryptedStr, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    console.log(
      "decodedString decodedString => ",
      decrypted.toString(CryptoJS.enc.Utf8),
      typeof decrypted.toString(CryptoJS.enc.Utf8),
      decrypted
    );
    const decodedString = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
    const userId = removePIfStartsWithP(decodedString.userId)
    const response = {
      userId: userId,
      storeNo: decodedString.storeNo,
      type: decodedString.type,
      userRole: decodedString.userRole,
    };

    console.log("response => ", response);
    return {
      userId: userId,
      storeNo: decodedString.storeNo,
      type: decodedString.type,
      userRole: decodedString.userRole,
    };
  } catch (error) {
    let searchParams = decodeParams(encryptedStr);
    console.log(" error searchParams =>", error, searchParams);
    
    if (searchParams.storeNo === 'INT9') {
      return {
        userId: '10051311',
        storeNo: searchParams.storeNo,
        type: searchParams.type,
        userRole: '',
      };
    }
    return {
      userId: searchParams.userId,
      storeNo: searchParams.storeNo,
      type: searchParams.type,
      userRole: searchParams.userRole,
    };
  }
};

function getValues(userId) {
  var sb = "";
  for (var i = 0; i < userId.length; ++i) {
    switch (userId.charAt(i)) {
      case "0":
        sb = sb + "1";
        break;
      case "1":
        sb = sb + "2";
        break;
      case "2":
        sb = sb + "3";
        break;
      case "3":
        sb = sb + "4";
        break;
      case "4":
        sb = sb + "5";
        break;
      case "5":
        sb = sb + "6";
        break;
      case "6":
        sb = sb + "7";
        break;
      case "7":
        sb = sb + "8";
        break;
      case "8":
        sb = sb + "9";
        break;
      case "9":
        sb = sb + "9";
        break;
      default:
        sb = sb + userId.charCodeAt(i);
    }
  }
  return sb.toString();
}
