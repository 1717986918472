import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import './App.css';
import { StateProvider } from './hooks/useGlobalState';
import Routes from './Routes';
import Layout from './Layout';
import CustomLoader from './components/CustomLoader/CustomLoader';
import './services/axiosUtilService';
// import './scss/main.scss'

function App() {
  return (
    <>
      <React.Suspense fallback={<CustomLoader/>}>
        <StateProvider>
          <Router basename={process.env.PUBLIC_URL}>
            <Layout>
              <Routes />
            </Layout>
          </Router>
        </StateProvider>
      </React.Suspense>
    </>
  );
}

export default App;
