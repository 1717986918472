import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Route, Switch, Redirect } from "react-router-dom";


function Rotator() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />        
      </header>
    </div>
  );
}

export default Rotator;
