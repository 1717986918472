import React from "react";
import axios from "axios";
import { basicAuth } from "../commom/basicAuth";
import { postApiCall } from "../commom/ApiRouter";

 export const getValidatedUser = async (Request) => {
  const APIURL = `${process.env.REACT_APP_API_URL}/OpenCommunicationAPI/validatemarkdownUser`;
  var basicAuthVal = basicAuth(Request.userId);
  try {
    const response = await axios.post(APIURL, Request, {
      headers: {
        "Content-Type": "text/plain",
        authorization: "Basic " + basicAuthVal,
        guid: "",
        storeId: Request.storeId,
        userId: Request.userId,
      },
    });
    return response;
  } catch (error) {
    console.log(error);
    return {
      errorCode: "01",
      errorMsg: "Error in call",
    };
  }
};

 export const getValidateUser = async (request) => {
  const Request = {
    ...request,
  };

  // const APIURL = `${process.env.REACT_APP_API_URL}/validatemarkdownUser`;
  const APIURL = `${process.env.REACT_APP_API_URL}/OpenCommunicationAPI/validateUser`;

  try {
    var basicAuthVal = basicAuth(Request.userId);
    const response = await axios.post(APIURL, Request, {
      headers: {
        "Content-Type": "text/plain",
        authorization: "Basic " + basicAuthVal,
        guid: "",
        storeId: Request.storeId,
      },
    });;
    console.log("response => ", response);
    
    if (response.status) {
      console.log(response);
    }
    return response;
  } catch (error) {
    console.error(error);
  }
};

 export const getInsertSMDetails = async (request) => {
  const Request = {
    ...request,
  };

  const APIURL = `${process.env.REACT_APP_API_URL}/OpenCommunicationAPI/insertdataSM`;
  try {
    const response = await postApiCall(Request, APIURL);
    if (response.status) {
      console.log(response);
    }
    return response;
  } catch (error) {
    console.error(error);
  }
};


export default getValidatedUser