import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { postApiCall } from '../commom/ApiRouter';
import { decryptData, Encrypt, encryptionDataWithRandomKey, encryptHeader } from '../commom/Encryption-Decryption';


const uploadFilesServc = async (commTypeId, uploadedFileNames,excelName,excel) => {
    const Request = {
        "commTypeId": commTypeId,
        "files": uploadedFileNames,
        "receiver_file": [{fileName:excelName,file:excel}]
    };

    const APIURL = `${process.env.REACT_APP_API_URL}/OpenCommunicationAPI/uploadFiles`;
    try {
        const response = await postApiCall(Request, APIURL);
        if (response.status) {
        }
        return response;
        // const DecryptedResponse = decryptData(response.replace(/"/g, ""));
        // if (numcheck(DecryptedResponse)) {
        //     confirmAlert({
        //         title: "Alert!",
        //         message: getHttpStatus(DecryptedResponse),
        //         buttons: [
        //             {
        //                 label: 'OK',
        //                 onClick: () => { return false }
        //             }
        //         ]
        //     });
        // }
        // else {
        //     return DecryptedResponse;
        // }

    } catch (error) {
        console.error(error);
    }

};

export const getFileService = (name,commTypeId,type="null") => {
    let req = {
        "fileName": name,
        "commTypeId": commTypeId,
        "type":type
    }
    let encReq = Encrypt(req, true);

     return `${process.env.REACT_APP_API_URL}/OpenCommunicationAPI/downloadDocuments?key=` + encReq;
}

export default uploadFilesServc;
