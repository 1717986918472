import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Rotator from "./Rotator";
import { decodeParams } from "./commom/basicAuth";
import { useHistory, useLocation } from "react-router-dom";

// import getValidateUser from "./services/validateUser";

const Login = React.lazy(() => import("./components/Login/Login"));
const CreatePost = React.lazy(() =>
  import("./components/CreatePost/CreatePost")
);
const CreatePostNew = React.lazy(() =>
  import("./components/CreatePostNew/CreatePostNew")
);
const Communication = React.lazy(() =>
  import("./components/Communication/Communication")
);
const StoreCommunication = React.lazy(() =>
  import("./components/StoreCommunication/StoreCommunication")
);
const StoreCommunicationDetails = React.lazy(() =>
  import("./components/StoreCommunication/StoreCommunicationDetails")
);
const LoginNew = React.lazy(() => import("./components/Login/LoginNew"));
const Dashboard = React.lazy(() => import("./components/Dashboard/Dashboard"));
const SalesStockPrice = React.lazy(() =>
  import("./components/SalesStockPrice/SalesStockPrice")
);
const Details = React.lazy(() => import("./components/Details/Details"));
const DailySale = React.lazy(() => import("./components/DailySale/DailySale"));
const WeeklySale = React.lazy(() =>
  import("./components/WeeklySale/WeeklySale")
);
const Location = React.lazy(() => import("./components/Location/Location"));
const Sidebar = React.lazy(() => import("./components/Sidebar/Sidebar"));

const Routes = () => {
  const location = useLocation();
  const [isValid, setIsValid] = useState(false);
  // const callValidate = async () => {
  //   try {
  //     let encodedQueryString = location.search.substring(1);
  //     let searchParams = decodeParams(encodedQueryString);
  //     const getValidateUserData = await getValidateUser({
  //       userId: searchParams.userId,
  //       storeId: searchParams.userRole === "SM-VF" ? searchParams.storeNo : "",
  //     });
  //     if (getValidateUserData.errorCode === 401) {
  //       setIsValid(false);
  //     } else {
  //       setIsValid(false);
  //     }
  //   } catch (error) {
  //     console.log("error => ", error);
  //   }
  // };

  // useEffect(() => {
  //   callValidate();
  // }, []);
  console.log("GUID LOG", sessionStorage.getItem("GUID"));

  return (
    <Switch>
      <Route exact path="/" component={LoginNew} />
      <Route exact path="/loginNew" component={LoginNew} />
      {sessionStorage.getItem("GUID") ? (
        <>
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/rotator" component={Rotator} />
          <Route exact path="/createPost" component={CreatePost} />
          <Route exact path="/createPostNew" component={CreatePostNew} />
          <Route exact path="/communication" component={Communication} />
          <Route
            exact
            path="/storeCommunication"
            component={StoreCommunication}
          />
          <Route
            exact
            path="/storeCommunicationDetails"
            component={StoreCommunicationDetails}
          />
          <Route exact path="/salesStockPrice" component={SalesStockPrice} />
          <Route exact path="/details" component={Details} />
          <Route exact path="/dailySales" component={DailySale} />
          <Route exact path="/weeklySales" component={WeeklySale} />
          <Route exact path="/location" component={Location} />
          <Route exact path="/sidebar" component={Sidebar} />{" "}
        </>
      ) : (
        <Route exact path="/*" component={Unauthorized} />
      )}
      <Redirect to="/" />
    </Switch>
  );
};

const Unauthorized = () => {
  console.log("Inside Unauthorized");

  return <h2>Unauthorized. You Don't have access</h2>;
};
export default Routes;
