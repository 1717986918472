import { getFileService } from "../services/uploadFilesServc";
import readXlsxFile from 'read-excel-file';
//Added by cc
export function numcheck(e) {

    const re = /^[0-9\b]+$/;
    if (e !== '' && re.test(e)) {
        return true;
    }
    else if (e !== '') {
        return false;
    }
}

// async method to fetch files for draft and resend communication
export async function promiseForFileFetch(element,item,type) {
  let url = getFileService(element,item.commTypeId,type)
  let response = await fetch(url)
  let blob=await response.blob();

  return  await new Promise((resolve, reject) =>{
      var reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = function() {
          return resolve({files:reader.result,size:blob.size});
      }
  })
};

export const fetchFiles = async (item) => {
  let uploadedFileNames = [];
  await  Promise.allSettled(item.files.split(",").map(async(element) => {
      if(!!element.trim()) {
          let {files,size}= await promiseForFileFetch(element,item,"files")
          uploadedFileNames.push({fileName:element,file:files,fileSize:size})
      }
  }))
  let {files:receiver_doc}=await promiseForFileFetch(item.receiver_file,item,"null")
  item['receiver_doc']=receiver_doc
  return {item,uploadedFileNames}
};

const dataURLtoFile=async (dataUrl, fileName)=>{
  const res = await fetch(dataUrl);
  const blob = await res.blob();
  return new File([blob], fileName);
};

export const convertExcelValuesToArray = async (val,fileName) =>{

  const isFile = val instanceof File;

  if(!isFile){
    val = await dataURLtoFile(val,fileName)
  }

  return readXlsxFile(val).then((rows, files) => {
      rows.shift()
      if(rows.length==0){
          throw "Excel Sheet cannot be empty"
      }
      let dataValues=[]
      rows.forEach((activity) => {
          let filteredArray = activity.filter(item => item)
          if(!(filteredArray.length===activity.length)){
              throw ("Please fill all the values in Excel Sheet")
          }
          let data = {
          storeNo : activity[0],
          storeEmail : activity[1],
          zone : activity[2],
          rrState : activity[3],
          city : activity[4],
          storeName : activity[5],
          technicianId : activity[6],
          storeManager : activity[7],
          afmEcNo : activity[8],
          zonalEcNo : activity[9],
          }
          dataValues.push(data)

      })
      return dataValues
  })
  .catch((e) =>
      {
        throw e
      }
  )
};

//added by cc
export function getHttpStatus(code) {
    switch(code) {
        case 100:
          return "Continue";

        case 304:
          return "Not Modified";

          case 305:
          return "Use Proxy";

          case 400:
          return "Bad Request";

          case 401:
          return "Unauthorized";

          case 403:
          return "Forbidden";

          case 404:
          return "Not Found";

          case 408:
          return "Request Timeout";

          case 407:
          return "Proxy Authentication Required";

          case 409:
          return "Conflict";

          case 500:
          return "Internal Server Error";

          case 501:
          return "Not Implemented";

          case 502:
          return "Bad Gateway";

          case 503:
          return "Service Unavailable";

          case 504:
          return "Gateway Timeout";

          case 505:
          return "HTTP Version Not Supported";


        default:
        return "Some Error occured.Please try again"
      }

}



