import React from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import Rotator from './Rotator';
const Login = React.lazy(() => import('./components/Login/Login'));
const CreatePost = React.lazy(() => import('./components/CreatePost/CreatePost'));
const CreatePostNew = React.lazy(() => import('./components/CreatePostNew/CreatePostNew'));
const Communication = React.lazy(() => import('./components/Communication/Communication'));
const StoreCommunication = React.lazy(() => import('./components/StoreCommunication/StoreCommunication'));
const StoreCommunicationDetails = React.lazy(() => import('./components/StoreCommunication/StoreCommunicationDetails'));
const LoginNew = React.lazy(() => import('./components/Login/LoginNew'));
const Dashboard = React.lazy(() => import('./components/Dashboard/Dashboard'));
const SalesStockPrice = React.lazy(() => import('./components/SalesStockPrice/SalesStockPrice'));
const Details = React.lazy(() => import('./components/Details/Details'));
const DailySale = React.lazy(() => import('./components/DailySale/DailySale'));
const WeeklySale = React.lazy(() => import('./components/WeeklySale/WeeklySale'));
const Location = React.lazy(() => import('./components/Location/Location'));
const Sidebar = React.lazy(() => import('./components/Sidebar/Sidebar'));



const Routes = () => {
    return (
        <Switch>
            {/* <Route exact path="/rotator" component={Rotator} /> */}
            <Route exact path="/createPost" component={CreatePost} />
            <Route exact path="/createPostNew" component={CreatePostNew} />
            {/* <Route exact path="/communication" component={Communication} /> */}
            <Route exact path="/storeCommunication" component={StoreCommunication} />
            <Route exact path="/storeCommunicationDetails" component={StoreCommunicationDetails} />
            <Redirect to='/storeCommunication' />
        </Switch>
    );
}

export default Routes;
