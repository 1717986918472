import React, { useState } from 'react';
import { css } from "@emotion/react";
import HashLoader from "react-spinners/HashLoader";
import './CustomLoader.css';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const CustomLoader = ({ loading }) => {

  return (
    <div className="spin" >
      <HashLoader color={"#E02E16"} loading={loading} css={override} size={100} />
    </div>
  )

}

export default CustomLoader
