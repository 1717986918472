const initialState = {
    app: {
        loading: false,
        communications: [],
        // communications : [
        //     {
        //         "subject" : "Open Communication Post",
        //         "viewedBy" : "44",
        //         "priority" : "high",
        //         "type" : "Action",
        //         "from" : "Retail Support Admin",
        //         "purposeComm": "Citrix Workspace App is the client component of XenDesktop and XenApp, developed by Citrix Systems. It was released initially in 2009"
        //     },
        //     {
        //         "subject" : "Open Communication Post",
        //         "viewedBy" : "44",
        //         "priority" : "high",
        //         "type" : "Action",
        //         "from" : "Retail Support Admin",
        //         "purposeComm": "Citrix Workspace App is the client component of XenDesktop and XenApp, developed by Citrix Systems. It was released initially in 2009"
        //     },
        //     {
        //         "subject" : "Open Communication Post",
        //         "viewedBy" : "44",
        //         "priority" : "high",
        //         "type" : "Action",
        //         "from" : "Retail Support Admin",
        //         "purposeComm": "Citrix Workspace App is the client component of XenDesktop and XenApp, developed by Citrix Systems. It was released initially in 2009"
        //     },
        //     {
        //         "subject" : "Open Communication Post",
        //         "viewedBy" : "44",
        //         "priority" : "high",
        //         "type" : "Action",
        //         "from" : "Retail Support Admin",
        //         "purposeComm": "Citrix Workspace App is the client component of XenDesktop and XenApp, developed by Citrix Systems. It was released initially in 2009"
        //     }

        // ],

        // communication:
        // {
        //     "from": "Retail Support Admin",
        //     "to": "Test User",
        //     "subject": "OTC POG implementation at ISKON store",
        //     "type": "Action",
        //     "instruction": "1.Citrix Workspace App is the client component of XenDesktop and XenApp, developed by Citrix Systems. It was released initially in 2009\n2.Citrix Workspace App is the client component of XenDesktop and XenApp, developed by Citrix Systems. It was released initially in 2009\n3. Citrix Workspace App is the client component of XenDesktop and XenApp, developed by Citrix Systems. It was released initially in 2009", "purposeComm": "Citrix Workspace App is the client component of XenDesktop and XenApp, developed by Citrix Systems. It was released initially in 2009",
        //     "userId": "1100052",
        //     "priority": "Moderate",
        //     "activityPerform": "immediate",
        //     "activityPerformBy": "AS/SM"
        // },

        communication:{},
        unreadCommNum : 0

    }
}

export default initialState